import axios from '@axios'

export default {
  namespaced: true,
  state: {
    load: false,
  },
  getters: {
    getLoad(state) {
      return state.load
    },
  },
  mutations: {
    alteraLoading(state, data) {
      state.load = data
    },
  },
  actions: {
    deleteItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/formatos/${id}`)
          .then(response => resolve())
          .catch(error => reject(error))
      })
    },

    fetchDados({ commit }, queryParams) {
      commit('alteraLoading', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/formatos', { params: queryParams })
          .then(response => {
            resolve(response.data)
            commit('alteraLoading', false)
          })
          .catch(error => console.log(error))
      })
    },

    listaSubdisciplinas() {
      return new Promise((resolve, reject) => {
        axios
          .get('/subdisciplinas')
          .then(response => {
            resolve(response.data.data)
          })
          .catch(error => console.log(error))
      })
    },

    store(ctx, itemData) {
      const formData = new FormData()

      const listaSubdisciplinas = itemData.sub_disciplinas.map(item => {
        if (item.id) {
          return item.id
        }
        return item.uuid
      })

      Object.keys(listaSubdisciplinas)
        .forEach(chave => {
          formData.append('subDisciplinas[]', listaSubdisciplinas[chave])
        })
      formData.append('descricao', itemData.descricao)

      return new Promise((resolve, reject) => {
        axios
          .post('/formatos', formData)
          .then(response => resolve(response => {
          }))
          .catch(error => reject(error))
      })
    },

    show(ctx, item) {
      return new Promise((resolve, reject) => {
        axios
          .get(`formatos/${item.id.uuid}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    update(ctx, itemData) {
      const listaSubdisciplinas = itemData.sub_disciplinas.map(item => {
        if (item.id) {
          return item.id
        }
        return item.uuid
      })

      const formData = new FormData()

      formData.append('descricao', itemData.descricao)

      Object.keys(listaSubdisciplinas)
        .forEach(chave => {
          formData.append('subDisciplinas[]', listaSubdisciplinas[chave])
        })

      formData.append('_method', 'PUT')
      return new Promise((resolve, reject) => {
        axios
          .post(`formatos/${itemData.uuid}`, formData)
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
